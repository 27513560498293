<template>
	<el-config-provider :locale="zhCn" >
		<router-view />
	</el-config-provider>
</template>
<script setup>
import { computed, ref, reactive, onMounted, onUnmounted } from 'vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import axiosReqest from '@/request/request.js';
import { useStore } from '@/pinia/index.js'

	let resizeTimeout = ref(null);
    let store = useStore();

	function windowSizeChange(){
		if(resizeTimeout) clearTimeout(resizeTimeout);
		resizeTimeout = setTimeout(() => {
			store.windowResize();
		}, 500);
	}
	async function getUserInfo(){
		let userInfoRes = await axiosReqest.postRequst('/system/user/showUserInfo');
		if(!userInfoRes.code == 0) return;
		console.log(userInfoRes);
	}
	onMounted(() => {
		getUserInfo();
		window.addEventListener('resize', windowSizeChange);
	})
	onUnmounted(() => {
		window.removeEventListener('resize', windowSizeChange);
	})
</script>
<style lang="scss">
@use './styles/app.scss';
</style>
