import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import AnimatedNumber from 'animated-number-vue3' //数字动态
import { createPinia } from 'pinia';


const app = createApp(App);
// app.config.globalProperties.$MY_CONSTANT = '';
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.devtools = false;
app.use(ElementPlus).
use(AnimatedNumber).
use(createPinia()).
use(router).
mount('#app');
