import { defineStore } from 'pinia'
import router from '../router';

// useStore 可以是 useUser、useCart 之类的任何东西
// 第一个参数是应用程序中 store 的唯一 id
export const useStore = defineStore('main', {
    state: () => {
        return {
            activeElModel: 1, // 监管首页 活动模块 左（1 2 3），右（4 5 6），下（7 8）
            mapCityData: [],
            activeMapInfo:{
                // zoom: 1,
                // code: '150100000000', //'100000000000',
                // name: '呼和浩特市',
                // level: 2
                zoom: 1,
                code: '150100000000',
                name: '呼和浩特市',
                level: 2
            },
            userInfo:{
                deptName: '',
                deptId: '',
                deptTypeId: '',
                deptTypeGroup: '',
                phonenumber: '',
                userId: '',
                userName: '',
                roleNames: '',
                roleId: '',
                zoom: 1,
                code: '150100000000',
                name: '呼和浩特市',
                level: 2
            },
            screenfullState: false,
            windowResizeTemp: 0,
            colorList: ['#4ccff4', '#62a5f2', '#2c9e1c', '#6d7881', '#d2b06f', '#6d7881', '#f1552f', '#ee8121', '#258fb3', '#dbd436', '#63a2a8', '#176bb6', '#2c9e1c', '#5cad85', '#2e99d7', '#7dbed1', '#f1552f', '#2792fc', '#ee8121', '#ee8121', '#88a3e8', '#80edbf', '#9093c4', '#4ccff4', '#5d8c8a', '#afd1c3', '#2ac67e', '#23b7bf', '#80edbf', '#3e6aea', '#fda327', '#ee8121', '#179bba', '#2792fc', '#89ada6', '#fda327', '#3e6aea', '#4ccff4', '#2bd6c9', '#a1afc8', '#5d8c8a', '#dbd436', '#4ccff4', '#fec365', '#62a5f2', '#89ada6', '#63a2a8', '#fda327', '#3e6aea', '#2e99d7', '#5cad85'],
            menuList: [],
            menuDefaultConfig: {
                isCollapse: false,
                navDefaultActive: '1',
            },
            navList: [{ menuName: '管理首页', path: '/', index: '1',includeName: 'home'}],
            includeNameList: ['home'],
            menuData: [
                // {
                //     path: '',
                //     menuName: '消防安全指数',
                //     menuId: 9,
                //     index: '9',
                //     icon: '&#xe600;',
                //     openFlag: false,
                //     children: [],
                // },
                {
                    menuName: '单位管理',
                    menuId: 3,
                    index: '3',
                    icon: '&#xe7e1;',
                    openFlag: false,
                    children: [
                        {
                            index: '301',
                            menuName: '单位列表',
                            menuId: 4,
                            parentId: '3',
                            children: [],
                            path: '/deptList',
                            includeName: 'deptList',
    
                        },
                        {
                            index: '302',
                            menuName: '用户列表',
                            menuId: 7,
                            parentId: '3',
                            children: [],
                            path: '/userList',
                            includeName: 'userList',
    
                        }
                    ],
                    
                },{
                    menuName: '点位管理',
                    menuId: 5,
                    index: '5',
                    icon: '&#xe618;',
                    openFlag: false,
                    children: [
                        {
                            index: '501',
                            menuName: '点位列表',
                            path: '/mountPointList',
                            includeName: 'mountPointList',
                            menuId: 6,
                            parentId: '5',
                            children: [],
    
                        }
                    ],
                    
                },{
                    menuName: '事件中心',
                    menuId: 8,
                    index: '8',
                    icon: '&#xe62e;',
                    openFlag: false,
                    children: [
                        {
                            index: '804',
                            menuName: '报警列表',
                            path: '/alarmList',
                            includeName: 'alarmList',
                            menuId: 12,
                            parentId: '8',
                            children: [],
    
                        },{
                            index: '801',
                            menuName: '故障列表',
                            path: '/faultList',
                            includeName: 'faultList',
                            menuId: 9,
                            parentId: '8',
                            children: [],
    
                        },
                        {
                            index: '802',
                            menuName: '隐患列表',
                            path: '/dangerList',
                            includeName: 'dangerList',
                            menuId: 10,
                            parentId: '8',
                            children: [],
    
                        },
                        {
                            index: '803',
                            menuName: '离线列表',
                            path: '/offlineList',
                            includeName: 'offlineList',
                            menuId: 11,
                            parentId: '8',
                            children: [],
    
                        }
                    ],
                    
                },{
                    menuName: '安全监管',
                    menuId: 8,
                    index: '11',
                    icon: '&#xe61c;',
                    openFlag: false,
                    children: [
                        {
                            index: '1101',
                            menuName: '安全巡查',
                            path: '/securityRecord',
                            includeName: 'securityRecord',
                            menuId: 15,
                            parentId: '11',
                            children: [],
                        },{
                            index: '1102',
                            menuName: '消控室',
                            path: '/fireControlRoomRecord',
                            includeName: 'fireControlRoomRecord',
                            menuId: 16,
                            parentId: '11',
                            children: [],
                        },{
                            index: '1103',
                            menuName: '重点部位',
                            path: '/keyAreasRecord',
                            includeName: 'keyAreasRecord',
                            menuId: 17,
                            parentId: '11',
                            children: [],
                        },{
                            index: '1104',
                            menuName: '维保评估',
                            path: '/mainTenanceRecord',
                            includeName: 'mainTenanceRecord',
                            menuId: 18,
                            parentId: '11',
                            children: [],
                        },
                    ],
                },{
                    menuName: '统计分析',
                    menuId: 8,
                    index: '10',
                    icon: '&#xe609;',
                    openFlag: false,
                    children: [
                        {
                            index: '1001',
                            menuName: '报警分析',
                            path: '',
                            includeName: 'offlineList',
                            menuId: 11,
                            parentId: '10',
                            children: [],
                        },{
                            index: '1002',
                            menuName: '故障分析',
                            path: '',
                            includeName: 'offlineList',
                            menuId: 12,
                            parentId: '10',
                            children: [],
                        },{
                            index: '1003',
                            menuName: '隐患分析',
                            path: '',
                            includeName: 'offlineList',
                            menuId: 13,
                            parentId: '10',
                            children: [],
                        },{
                            index: '1004',
                            menuName: '离线分析',
                            path: '',
                            includeName: 'offlineList',
                            menuId: 14,
                            parentId: '10',
                            children: [],
                        },
                    ],
                },{
                    menuName: '权限管理',
                    menuId: 8,
                    index: '12',
                    icon: '&#xe713;',
                    openFlag: false,
                    children: [
                        {
                            index: '1201',
                            menuName: '用户管理',
                            path: '',
                            includeName: 'offlineList',
                            menuId: 11,
                            parentId: '12',
                            children: [],
                        },{
                            index: '1202',
                            menuName: '角色管理',
                            path: '',
                            includeName: 'offlineList',
                            menuId: 11,
                            parentId: '12',
                            children: [],
                        }
                    ],
                },{
                    menuName: '系统设置',
                    menuId: 8,
                    index: '13',
                    icon: '&#xe771;',
                    openFlag: false,
                    children: [
                        {
                            index: '1301',
                            menuName: '平台设置',
                            path: '',
                            includeName: 'offlineList',
                            menuId: 11,
                            parentId: '13',
                            children: [],
                        },{
                            index: '1302',
                            menuName: '个人设置',
                            path: '',
                            parentId: '13',
                            includeName: 'offlineList',
                            menuId: 11,
                            children: [],
                        }
                    ],
                }
            ]
        }
    },
    actions:{
        // 设置用户信息
        setUserInfo(res){
            for (const key in res) {
                this.userInfo[key] = res[key];
            }
        },
        // 设置菜单展开关闭
        setMenuCollapseState(res){
            this.menuDefaultConfig.isCollapse = !this.menuDefaultConfig.isCollapse;
        },
        // 设置Tab头部菜单
        setNavTabsActive(data){
            let res = data.type == 'home' ? this.navList[0] : data;
            let navList = this.navList;
            let navIndexList = navList.map(x=>{return x.index});
            let openFlag = navIndexList.includes(res.index);
            // 菜单是否已打开 存在已打开
            this.menuDefaultConfig.navDefaultActive = res.index;
            let includeIndex = this.includeNameList.indexOf(res.includeName);
            if(openFlag){
                router.push({path: res.path});
                if(includeIndex == -1) this.includeNameList.push(res.includeName);
            }else{
                this.navList.push({
                    menuName: res.menuName,
                    path: res.path,
                    index: res.index,
                    includeName: res.includeName,
                });
                router.push({path: res.path});
                this.includeNameList.push(res.includeName);
            }
        },
        // tabs删除时设置动态菜单
        removeSetMenuActive(res){
            // 查询删除项
            let removeItem = this.navList.find((item)=>{
                return item.index == res.targetName;
            })
            // 过滤删除项缓存
            this.includeNameList = this.includeNameList.filter(item=>{
                return item != removeItem.includeName;
            })
            // 过滤删除项
            this.navList = this.navList.filter((item)=>{
                return item.index != res.targetName;
            })
            if(res.targetName == this.menuDefaultConfig.navDefaultActive){
                // 设置高亮值
                this.menuDefaultConfig.navDefaultActive = res.activeItem.index;
            }
            
        },
        // 删除指定页面缓存
        removeIncludeName(res){
            this.includeNameList = this.includeNameList.filter(item=>{
                return res.indexOf(item) == -1;
            })
        },
        // 清除所有页面缓存
        removeIncludeAll(){
            this.includeNameList = ['home'];
            this.menuDefaultConfig.navDefaultActive = '1';

        },
        /**
         * 设置echarts地图导航列表
         * @param {*} res 
         * @param {*} flag 操作标识 0 返回指定等级，1添加下级，2重置
         */
        setMapCityData(res){
            if(res.flag == 0){
                this.mapCityData = this.mapCityData.slice(0, res.index+1);
            }else if(res.flag == 1) {
                this.mapCityData.push({
                    zoom: res.zoom,
                    code: res.code,
                    name: res.name,
                    level: res.level
                })
            }else {
                this.activeMapInfo.zoom = res.mapData.zoom;
                this.activeMapInfo.code = res.mapData.code;
                this.activeMapInfo.name = res.mapData.name;
                this.activeMapInfo.level = res.mapData.level;
                this.mapCityData = [];
            }
        },
        //窗口大小变化 
        windowResize(){
            this.windowResizeTemp ++;
        },
        // 设置全屏标识
        setScreenfull(res){
            this.screenfullState = res;
        },
    }
})