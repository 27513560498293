import Axios from "@/request/index";

// 通用post请求
function postRequst (url, form, headers){
    return new Promise((resolve, reject) => {
        try {
            Axios.post(url, form, headers).then(res=>{
                resolve(res);
            })
        } catch (error) { reject() }
    })
}

// 通用Get请求
function getRequst (url, form){
    return new Promise((resolve, reject) => {
        try {
            Axios.get(url, {params: form }).then(res=>{
                resolve(res);
            })
        } catch (error) { reject() }
    })   
}

// 上传文件请求
function filePostRequst (url, form){
    return new Promise((resolve, reject) => {
        try {
            
        } catch (error) { reject() }
    })   
}
function websocketsClient(){

}
export default {
    postRequst,
    getRequst,
    filePostRequst,
    websocketsClient
}