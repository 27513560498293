import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
		path: '/login',
		name: 'login',
		component: ()=>import('@/pages/login/login.vue')
    },
	{
		path: '/monitor',
		name: 'monitor',
		component: ()=>import('@/pages/monitor/index.vue')
	},
	{
		path: '/city-data',
		name: 'cityData',
		component: ()=>import('@/pages/monitor/views/city-map.vue')
	},
	{
		path: '/',
		name: 'index',
		component: ()=>import('@/pages/home/index.vue'),
		children: [
			{
				path: '/',
				name: 'home',
				component: () => import('@/pages/home/views/home.vue')
			},
			{
				path: '/deptList',
				name: 'deptList',
				component: () => import('@/pages/home/views/detpManage/deptList.vue')
			},
			{
				path: '/deptDetail',
				name: 'deptDetail',
				component: () => import('@/pages/home/views/detpManage/deptDetail.vue')
			},
			{
				path: '/userList',
				name: 'userList',
				component: () => import('@/pages/home/views/detpManage/userList.vue')
			},
			{
				path: '/alarmList',
				name: 'alarmList',
				component: () => import('@/pages/home/views/eventManage/alarmList.vue')
			},
			{
				path: '/faultList',
				name: 'faultList',
				component: () => import('@/pages/home/views/eventManage/faultList.vue')
			},
			{
				path: '/dangerList',
				name: 'dangerList',
				component: () => import('@/pages/home/views/eventManage/dangerList.vue')
			},
			{
				path: '/offlineList',
				name: 'offlineList',
				component: () => import('@/pages/home/views/eventManage/offlineList.vue')
			},
			{
				path: '/mountPointList',
				name: 'mountPointList',
				component: () => import('@/pages/home/views/placeManage/mountPointList.vue')
			},
			{
				path: '/securityRecord',
				name: 'securityRecord',
				component: () => import('@/pages/home/views/securityManage/securityRecord.vue')
			},
			{
				path: '/fireControlRoomRecord',
				name: 'fireControlRoomRecord',
				component: () => import('@/pages/home/views/securityManage/fireControlRoomRecord.vue')
			},
			{
				path: '/keyAreasRecord',
				name: 'keyAreasRecord',
				component: () => import('@/pages/home/views/securityManage/keyAreasRecord.vue')
			},
			{
				path: '/mainTenanceRecord',
				name: 'mainTenanceRecord',
				component: () => import('@/pages/home/views/securityManage/mainTenanceRecord.vue')
			},
		]
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
router.beforeEach(function(to, from, next) {
	
	let token = localStorage.getItem('token')
    if ( !token ) {
        if (to.path !== '/login') {
			localStorage.clear();
            return next('/login');
        }
    }
	// console.log(store);
    next();
})

export default router
