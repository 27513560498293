import axios from 'axios'
import qs from 'qs';
import router from '@/router/index'

export const baseURL = process.env.NODE_ENV == 'development' ? '/api' : process.env.VUE_APP_BASEURL;
const Axios = axios.create({
    //axios 配置 ： baseURL timeout
    baseURL,
    timeout: 30000, //5S超时
});
//请求拦截
Axios.interceptors.request.use((config) => {
    const token = localStorage.token || '';
    config.headers.Authorization = token;
    if(config.method == 'post'){
        config.data = qs.stringify(config.data, {arrayFormat: 'brackets'});
    }
    return config;
},(err) => {
    return Promise.reject(err);
});
//响应拦截
Axios.interceptors.response.use((response) => {
    if(response.data.code == 5000){
        router.push('/login');
        return
    }
    return response.data;
},(err) => {
    if(err.response && err.response.status == 5000){
        return router.push('/login')
    }
    return Promise.reject(err)
});
export default Axios